import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          New website coming soon!
        </p>
      </header>
      <a
        className="App-link"
        href="main.html"
      >
      </a>
    </div>
  );
}

export default App;
